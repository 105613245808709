<div class="auth-container reset-password-container">

  <div class="card-container">

    <app-header></app-header>

    @if (!hasRequestedResetPasswordLink) {
      <div class="auth-card">
        <div class="content-container">
          <h3 class="title">Forgot Password</h3>
          <p class="sub-title">Enter your registered email address, we'll send you a link to set the new password</p>
          <form class="form-container">
            <div class="email-input-container">
              <div class="label-container">
                <div class="input-label">Work Email</div>
                <span class="asterisk">*</span>
              </div>
              <div class="validation-wrapper tw-relative">
                <input
                  class="input-content input-text"
                  autocomplete="off"
                  [formControl]="resetPasswordForm"
                  name="email"
                  type="email"
                  placeholder="Your Email Address"
                  appAutofocus
                  [ngClass]="{'p-error' : resetPasswordForm.touched && resetPasswordForm.invalid}"
                  >
                <div class="error-space">
                </div>
                @if (resetPasswordForm.valid) {
                  <div class="action-wrapper" >
                    <span class="action-text">
                      <app-svg-sprite icon="check" class="success-icon"></app-svg-sprite>
                    </span>
                  </div>
                }
                @if (resetPasswordForm.touched && resetPasswordForm.errors) {
                  <div class="action-wrapper">
                    <span class="action-text">
                      <app-svg-sprite icon="danger-fill" class="error-icon"></app-svg-sprite>
                    </span>
                  </div>
                }
              </div>
              @if (resetPasswordForm.touched && resetPasswordForm.errors) {
                @if (resetPasswordForm.hasError('required')) {
                  <div class="error">Please enter an email address</div>
                }
                @if (resetPasswordForm.hasError('email')) {
                  <div class="error">Please enter a valid email address</div>
                }
              }
            </div>
            <ui-button
              [defaultType]="'submit'"
              [buttonType]="buttonType.PRIMARY"
              (buttonClick)="sendResetLink()"
              [validateForm]="true"
              [isLoading]="showLoaderOnButton"
              [label]="'Request Reset Link'"
              [customClasses]="'button-full font-700 padding-14'"
              [loadingText]="'Requesting'">
            </ui-button>
            <div class="alternate-text" (click)="goToSignIn()">
              <p>&#60;</p>
              <div>Back to sign in</div>
            </div>
          </form>
        </div>
      </div>
    }

    @if (hasRequestedResetPasswordLink) {
      <div class="auth-card">
        <div class="content-container tw-text-center">
          <app-svg-sprite [icon]="'reset-email'" class="reset-email"></app-svg-sprite>
          <h3 class="check-email-text">Check Your Mail</h3>
          <div class="sent-mail">
            @if (!canChangeContent) {
              <p class="tw-m-0 tw-p-0 message-1">We have sent a reset link on your email address,</p>
            }
            @if (canChangeContent) {
              <p class="tw-m-0 tw-p-0 message-1">If there's a {{ targetConfig.BRAND_NAME }} account linked to this email address, we'll send over instructions to reset your password.</p>
            }
            <p class="email">{{ this.resetPasswordForm.value }}</p>
          </div>
          @if (!canChangeContent) {
            <div class="link-text">
              <p class="tw-p-0 tw-m-0 message-2">Didn't receive a link?</p>
              @if (!isResendAPI) {
                <p-progressSpinner
                  styleClass="spinner-14"
                  strokeWidth="5"
                  class="progress-spinner"
                  >
                </p-progressSpinner>
              }
              @if (isResendAPI) {
                <p class="resend-link" (click)="sendResetLink()">Resend Link</p>
              }
            </div>
          }
        </div>
        <div class="or-text-container">
          <span class="or-text">
            OR
          </span>
        </div>
        <ui-button
          [defaultType]="'submit'"
          [buttonType]="buttonType.PRIMARY"
          [customClasses]="'text-uppercase font-700 padding-14 button-full'"
          [label]="'Sign In'"
          (buttonClick)="goToSignIn()">
        </ui-button>
      </div>
    }

  </div>

  <!-- <footer class="footer">Never lose track</footer> -->
</div>
